// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f9fbff;
  color: #696969;
  font-family: 'Public Sans';
  font-size: 14px;
  --toastify-font-family: 'Public Sans';
}

a {
  text-decoration: none;
  color: inherit;
}

/* Hide number input controls */
/* Chrome, Safari, Edge, Opera */
.disable-number-controls::-webkit-outer-spin-button,
.disable-number-controls::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.disable-number-controls[type='number'] {
  -moz-appearance: textfield;
}

/* Calendar styles */
.custom-calendar {
  width: 100%;
}

.image-edit-container {
  background-color: #00000080;
  border-radius: 16px;
  visibility: hidden;
  /* transition: all 0.3s; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.image-container:hover .image-edit-container {
  visibility: visible;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,UAAU;EACV,mCAAmC;EACnC,kCAAkC;EAClC,mBAAmB;EACnB,cAAc;EACd,0BAA0B;EAC1B,eAAe;EACf,qCAAqC;AACvC;;AAEA;EACE,qBAAqB;EACrB,cAAc;AAChB;;AAEA,+BAA+B;AAC/B,gCAAgC;AAChC;;EAEE,wBAAwB;EACxB,SAAS;AACX;;AAEA,YAAY;AACZ;EACE,0BAA0B;AAC5B;;AAEA,oBAAoB;AACpB;EACE,WAAW;AACb;;AAEA;EACE,2BAA2B;EAC3B,mBAAmB;EACnB,kBAAkB;EAClB,0BAA0B;EAC1B,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":["body {\n  margin: 0;\n  padding: 0;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n  background: #f9fbff;\n  color: #696969;\n  font-family: 'Public Sans';\n  font-size: 14px;\n  --toastify-font-family: 'Public Sans';\n}\n\na {\n  text-decoration: none;\n  color: inherit;\n}\n\n/* Hide number input controls */\n/* Chrome, Safari, Edge, Opera */\n.disable-number-controls::-webkit-outer-spin-button,\n.disable-number-controls::-webkit-inner-spin-button {\n  -webkit-appearance: none;\n  margin: 0;\n}\n\n/* Firefox */\n.disable-number-controls[type='number'] {\n  -moz-appearance: textfield;\n}\n\n/* Calendar styles */\n.custom-calendar {\n  width: 100%;\n}\n\n.image-edit-container {\n  background-color: #00000080;\n  border-radius: 16px;\n  visibility: hidden;\n  /* transition: all 0.3s; */\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.image-container:hover .image-edit-container {\n  visibility: visible;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
