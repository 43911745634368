import { Navigate } from 'react-router-dom';

import { RoutePath } from 'constants/routes-path';
import { getTokenDetails } from 'utils/userSession';

export const PublicRoutes = ({ children }: React.PropsWithChildren) => {
  const user = getTokenDetails()?.accessToken;
  return user ? <Navigate to={RoutePath.DASHBOARD} replace /> : <>{children}</>;
};

export const ProtectedRoutes = ({ children }: React.PropsWithChildren) => {
  const user = getTokenDetails()?.accessToken;
  return !user ? <Navigate to={RoutePath.SIGN_IN} replace /> : <>{children}</>;
};
