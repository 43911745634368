import { useMutation } from '@apollo/client';
import { MenuItem, Stack } from '@mui/material';
import { ProductInventoryType } from 'components/inventory/data';
import { Switch, Typography } from 'components/shared';
import { TOGGLE_OUT_OF_STOCK } from 'graphql/mutations/inventory.mutation';
import React from 'react';
import { sendCatchFeedback, sendFeedback } from 'utils/feedback';

type Props = {
  handleClose: () => void;
  product: ProductInventoryType;
  refetch: () => void;
};

export const OutOfStockToggle: React.FC<Props> = ({ handleClose, product, refetch }) => {
  const [toggleOutOfStock] = useMutation(TOGGLE_OUT_OF_STOCK);

  const onSubmit = async () => {
    try {
      const response = await toggleOutOfStock({
        variables: {
          data: {
            product_id: product.id,
            status:
              product.out_of_stock_status === 'activate' ? 'inactivate' : 'activate',
          },
        },
      });

      if (response.data?.toggleOutOfStock?.status) {
        handleClose();
        refetch();

        return sendFeedback(response.data?.toggleOutOfStock?.message, 'success');
      } else {
        return sendFeedback(response.data?.toggleOutOfStock?.message, 'error');
      }
    } catch (error) {
      sendCatchFeedback(error);
    }
  };
  return (
    <MenuItem
      onClick={() => {
        onSubmit();
      }}
    >
      <Stack spacing={4} alignItems='center' direction='row'>
        {product.out_of_stock_status === 'activate' ? (
          <Typography>Turn off out of stock</Typography>
        ) : (
          <Typography>Turn on out of stock</Typography>
        )}
        <Switch checked={product.out_of_stock_status === 'activate'} />
      </Stack>
    </MenuItem>
  );
};
