import { gql } from '@apollo/client';

export const GET_ALL_INVENTORY = gql`
  query GET_ALL_INVENTORY(
    $page: Int
    $limit: Int
    $search: String
    $filters: FilterOptionsInventory
    $sortOrder: SortOrderCatalogue
    $sortBy: SortOptionsCatalogue
  ) {
    getInventory(
      page: $page
      limit: $limit
      search: $search
      filters: $filters
      sortBy: $sortBy
      sortOrder: $sortOrder
    ) {
      data {
        total_count
        limit
        page
        total_pages
        inventory {
          name
          description
          id
          is_new
          amount
          unit
          per_item
          uom
          valid_from
          valid_to
          brand
          product_line
          class_type
          color
          category
          created_at
          updated_at
          sap_id
          amount_sold
          quantity_in_stock
          min_order_quantity
          out_of_stock_status
          locked_status
          is_deleted
          images {
            url
          }
        }
        has_previous_page
        has_next_page
      }
      status
      message
    }
  }
`;

export const GET_INVENTORY_DETAILS = gql`
  query GET_INVENTORY_DETAILS($id: ID) {
    getInventoryById(id: $id) {
      data {
        name
        description
        id
        is_new
        amount
        unit
        per_item
        uom
        valid_from
        valid_to
        brand
        product_line
        class_type
        color
        category
        created_at
        updated_at
        sap_id
        amount_sold
        quantity_in_stock
        min_order_quantity
        out_of_stock_status
        locked_status
        is_deleted
        images {
          url
        }
      }
      status
      message
    }
  }
`;

export const GET_CATALOGUE_ENTITY = gql`
  query GET_CATALOGUE_ENTITY($entityType: CatalogueEntityEnum) {
    getAdminCatalogueEntity(entityType: $entityType) {
      data
      status
      message
    }
  }
`;

export const GET_SAP_CATALOGUE_DETAILS = gql`
  query GET_SAP_CATALOGUE_DETAILS($id: String) {
    getSapCatelogueDetails(id: $id) {
      data {
        sap_id
        product_name
        product_price
        price_unit
        quantity_unit
        product_per_item
        product_validity_from
        product_validity_to
      }
      status
      message
    }
  }
`;

export const GET_CATALOGUE_DETAILS = gql`
  query GET_CATALOGUE_DETAILS($id: String) {
    getCatelogueDetails(id: $id) {
      data {
        brand
        category
        class_type
        color
        rebate {
          rebate_group
          description
        }
        product_line
      }
      status
      message
    }
  }
`;
