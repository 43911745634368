import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Header, Sidebar } from 'components';
import { ProtectedRoutes } from 'middleware';

const ErrorBoundaryPage = () => {
  return (
    <ProtectedRoutes>
      <Box>
        <Sidebar />
        <Box>
          <Box component='main' marginLeft='15rem'>
            <Header />
            <Box maxWidth={1400} minHeight='100vh' paddingX={2} marginX='auto'>
              <Box paddingY={1.5}>
                <Box
                  display='flex'
                  justifyContent='center'
                  alignItems='center'
                  minHeight={400}
                >
                  <Typography variant='md'>
                    The page you are looking for is not available
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </ProtectedRoutes>
  );
};

export default ErrorBoundaryPage;
