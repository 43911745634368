import { Box, Stack } from '@mui/material';
import { Link } from 'react-router-dom';

import { ReactComponent as EyeIcon } from 'assets/svgs/eye.svg';
import { Typography } from 'components/shared';
import { colors } from 'theme/color';
import { DisplayStatusTypeValues } from 'types/data';
import { formatDate } from 'utils/format-date';
import { formatAmount, formatPhone, formatQuantity, formatTime } from 'utils/table';
import { getStatusColor } from './utils';

type DefaultProps = { columnValue: string };

const PhoneNumber = ({ columnValue }: DefaultProps) => (
  <>{columnValue ? formatPhone(columnValue) : '-'}</>
);
const Date = ({ columnValue }: { columnValue: Date }) => (
  <>{columnValue ? formatDate(columnValue) : '-'}</>
);
const Default = ({ columnValue }: { columnValue: string | number }) => (
  <>{columnValue || '-'}</>
);
const Quantity = ({
  columnValue,
  toWholeNumber = false,
  showZero,
}: {
  columnValue: string | number;
  toWholeNumber?: boolean;
  showZero?: boolean;
}) => (
  <>{columnValue ? formatQuantity(columnValue, toWholeNumber) : showZero ? "0" : '-'}</>
);
const Currency = ({
  columnValue,
  toWholeNumber = true,
}: {
  columnValue: string | number;
  toWholeNumber?: boolean;
}) => {
  const symbol = '₦';
  return (
    <>{columnValue ? `${symbol}${formatAmount(columnValue, toWholeNumber)}` : '-'}</>
  );
};
const Distance = ({ columnValue }: DefaultProps) => (
  <>{columnValue ? `${columnValue} km` : '-'}</>
);
const TimeString = ({ columnValue }: { columnValue: Date }) => (
  <>{columnValue ? formatTime(columnValue) : '-'}</>
);

const DetailsLink = ({
  destination,
  onClick,
}: {
  destination: string;
  onClick?: () => void;
}) =>
  onClick ? (
    <Stack
      direction='row'
      spacing='4px'
      useFlexGap
      alignItems='center'
      style={{ cursor: 'pointer' }}
      onClick={onClick}
    >
      <EyeIcon width={25} />
      <Typography variant='sm' color={colors.black.light}>
        VIEW DETAILS
      </Typography>
    </Stack>
  ) : (
    <Link to={destination}>
      <Stack direction='row' spacing='4px' useFlexGap alignItems='center'>
        <EyeIcon width={25} />
        <Typography variant='sm' color={colors.black.light}>
          VIEW DETAILS
        </Typography>
      </Stack>
    </Link>
  );

const StatusChip = ({ columnValue }: { columnValue: boolean }) => (
  <Stack
    bgcolor={columnValue ? colors.green.light : colors.red.faded}
    p={1}
    direction='row'
    spacing={1}
    borderRadius={'10px'}
    minWidth='fit-content'
    maxWidth='fit-content'
    alignItems='center'
  >
    <Box
      width='6px'
      height='6px'
      sx={{
        borderWidth: 3,
        borderStyle: 'solid',
      }}
      bgcolor={columnValue ? colors.green.main : colors.red.main}
      borderColor={columnValue ? colors.green.faded : colors.red.light}
      borderRadius={'50%'}
    />
    <Typography color={columnValue ? colors.green.main : colors.red.main}>
      {columnValue ? 'Active' : 'In Active'}
    </Typography>
  </Stack>
);

const OrderStatusChip = ({
  columnValue,
  showBackground = true,
}: {
  columnValue: DisplayStatusTypeValues;
  showBackground?: boolean;
}) =>
  showBackground ? (
    <Stack
      bgcolor={getStatusColor(columnValue).bg}
      p={1}
      direction='row'
      spacing={1}
      borderRadius={'10px'}
      minWidth='fit-content'
      maxWidth='fit-content'
      alignItems='center'
    >
      <Box
        width='6px'
        height='6px'
        sx={{
          borderWidth: 3,
          borderStyle: 'solid',
        }}
        bgcolor={getStatusColor(columnValue).text}
        borderColor={getStatusColor(columnValue).border}
        borderRadius={'50%'}
      />
      <Typography color={getStatusColor(columnValue).text} fontWeight='inherit'>
        {columnValue}
      </Typography>
    </Stack>
  ) : (
    <Typography color={getStatusColor(columnValue).text} fontWeight='inherit'>
      {columnValue}
    </Typography>
  );

export {
  Currency,
  Date,
  Default,
  DetailsLink,
  Distance,
  OrderStatusChip,
  PhoneNumber,
  Quantity,
  StatusChip,
  TimeString
};

