import loadable from '@loadable/component';
import { Suspense } from 'react';
import { createBrowserRouter, Outlet } from 'react-router-dom';

import { Loader } from 'components';
import SetPasswordLayout from 'components/auth/set-password/layout';
import { RoutePath } from 'constants/routes-path';
import AuthLayout from 'pages/auth/layout';
import DashboardLayout from 'pages/dashboard/layout';
import ErrorBoundaryPage from 'pages/error-boundary';

const loadableOptions = {
  fallback: <Loader />,
};
// LAZY LOAD PAGES TO IMPROVE PERFORMANCE

//MAIN PAGES
const DashboardPage = loadable(() => import('pages/dashboard'), loadableOptions);
const InventoryPage = loadable(
  () => import('pages/dashboard/inventory'),
  loadableOptions
);
const AddInventoryPage = loadable(
  () => import('pages/dashboard/inventory/add-inventory'),
  loadableOptions
);
const TransactionsPage = loadable(
  () => import('pages/dashboard/transactions'),
  loadableOptions
);
const SignInPage = loadable(() => import('pages/auth/sign-in'), loadableOptions);
const SetPasswordPage = loadable(
  () => import('pages/auth/set-password'),
  loadableOptions
);
const ForgotPasswordPage = loadable(
  () => import('pages/auth/forgot-password'),
  loadableOptions
);
const UpdateResetPasswordPage = loadable(
  () => import('pages/auth/update-reset-password'),
  loadableOptions
);
const VerifyResetOTPPage = loadable(
  () => import('pages/auth/verify-reset-otp'),
  loadableOptions
);
const AuditTrailPage = loadable(
  () => import('pages/dashboard/audit-trail'),
  loadableOptions
);
const CentersPage = loadable(() => import('pages/dashboard/centers'), loadableOptions);
const OrderManagementPage = loadable(
  () => import('pages/dashboard/order-management'),
  loadableOptions
);
const PartnersPage = loadable(() => import('pages/dashboard/partners'), loadableOptions);
const RepsPage = loadable(() => import('pages/dashboard/reps'), loadableOptions);

//PAGE DETAILS
const InventoryDetailPage = loadable(
  () => import('pages/dashboard/inventory/details'),
  loadableOptions
);
const AdminAuditDetailsPage = loadable(
  () => import('pages/dashboard/audit-trail/details/admin'),
  loadableOptions
);
const PartnerAuditDetailsPage = loadable(
  () => import('pages/dashboard/audit-trail/details/partner'),
  loadableOptions
);
const RepAuditDetailsPage = loadable(
  () => import('pages/dashboard/audit-trail/details/rep'),
  loadableOptions
);
const CentersDetailsPage = loadable(
  () => import('pages/dashboard/centers/details'),
  loadableOptions
);
const OrderDetailsPage = loadable(
  () => import('pages/dashboard/order-management/details'),
  loadableOptions
);
const PartnerDetailsPage = loadable(
  () => import('pages/dashboard/partners/details'),
  loadableOptions
);
const NotificationsPage = loadable(
  () => import('pages/dashboard/notifications'),
  loadableOptions
);
const MessagesPage = loadable(() => import('pages/dashboard/messages'), loadableOptions);
const SettingsPage = loadable(() => import('pages/dashboard/settings'), loadableOptions);
const ReportsPage = loadable(() => import('pages/dashboard/reports'), loadableOptions);
const UserDetailsSettingsPage = loadable(
  () => import('pages/dashboard/settings/user-details'),
  loadableOptions
);

const SuspenseLayout = () => (
  <Suspense fallback={<Loader />}>
    <Outlet />
  </Suspense>
);

export const router = createBrowserRouter([
  {
    errorElement: <ErrorBoundaryPage />,
    element: <DashboardLayout />,
    children: [
      {
        element: <SuspenseLayout />,
        children: [
          {
            path: RoutePath.DASHBOARD,
            element: <DashboardPage />,
          },
          {
            path: RoutePath.INVENTORY,
            children: [
              {
                index: true,
                element: <InventoryPage />,
              },
              {
                path: RoutePath.ADD_INVENTORY,
                element: <AddInventoryPage />,
              },
              {
                path: `${RoutePath.INVENTORY}/:id`,
                element: <InventoryDetailPage />,
              },
            ],
          },
          {
            path: RoutePath.TRANSACTIONS,
            element: <TransactionsPage />,
          },
          {
            path: RoutePath.CENTERS,
            children: [
              {
                index: true,
                element: <CentersPage />,
              },
              {
                path: `${RoutePath.CENTERS}/:id`,
                element: <CentersDetailsPage />,
              },
            ],
          },
          {
            path: RoutePath.PARTNERS,
            children: [
              {
                index: true,
                element: <PartnersPage />,
              },
              {
                path: `${RoutePath.PARTNERS}/:id`,
                element: <PartnerDetailsPage />,
              },
            ],
          },
          {
            path: RoutePath.ORDER_MANAGEMENT,
            children: [
              {
                index: true,
                element: <OrderManagementPage />,
              },
              {
                path: `${RoutePath.ORDER_MANAGEMENT}/:id`,
                element: <OrderDetailsPage />,
              },
            ],
          },
          {
            path: RoutePath.REPS,
            element: <RepsPage />,
          },
          {
            path: RoutePath.AUDIT_TRAIL,
            children: [
              {
                index: true,
                element: <AuditTrailPage />,
              },
              {
                path: `${RoutePath.AUDIT_TRAIL_ADMIN}/:id`,
                element: <AdminAuditDetailsPage />,
              },
              {
                path: `${RoutePath.AUDIT_TRAIL_PARTNER}/:id`,
                element: <PartnerAuditDetailsPage />,
              },
              {
                path: `${RoutePath.AUDIT_TRAIL_REP}/:id`,
                element: <RepAuditDetailsPage />,
              },
            ],
          },
          {
            path: RoutePath.NOTIFICATIONS,
            children: [
              {
                index: true,
                element: <NotificationsPage />,
              },
            ],
          },
          {
            path: RoutePath.MESSAGES,
            children: [
              {
                index: true,
                element: <MessagesPage />,
              },
            ],
          },
          {
            path: RoutePath.SETTINGS,
            children: [
              {
                index: true,
                element: <SettingsPage />,
              },
              {
                path: `${RoutePath.SETTINGS}/user-details/:id`,
                element: <UserDetailsSettingsPage />,
              },
            ],
          },
          {
            path: RoutePath.REPORTS,
            element: <ReportsPage />,
          },
        ],
      },
    ],
  },
  {
    path: RoutePath.SIGN_IN,
    element: (
      <AuthLayout showBackButton={false}>
        <SignInPage />
      </AuthLayout>
    ),
  },
  {
    path: `${RoutePath.SET_PASSWORD}/:email`,
    element: (
      <SetPasswordLayout>
        <SetPasswordPage />
      </SetPasswordLayout>
    ),
  },
  {
    path: RoutePath.FORGOT_PASSWORD,
    element: (
      <AuthLayout>
        <ForgotPasswordPage />
      </AuthLayout>
    ),
  },
  {
    path: `${RoutePath.VERIFY_RESET_OTP}/:email`,
    element: (
      <AuthLayout>
        <VerifyResetOTPPage />
      </AuthLayout>
    ),
  },
  {
    path: `${RoutePath.UPDATE_RESET_PASSWORD}/:email`,
    element: (
      <AuthLayout>
        <UpdateResetPasswordPage />
      </AuthLayout>
    ),
  },
]);
