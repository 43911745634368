import Box from '@mui/material/Box';
import React from 'react';

import { colors } from 'theme/color';

import { SidebarHeader } from './header';
import { SidebarLinks } from './links';

export const Sidebar: React.FC = () => {
  return (
    <Box
      component='aside'
      sx={{
        background: colors.secondary['main'],
        width: '15rem',
        height: '100vh',
        position: 'fixed',
      }}
    >
      <SidebarHeader />
      <SidebarLinks />
    </Box>
  );
};
