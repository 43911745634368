import { colors } from 'theme/color';
import { DisplayStatusTypeValues } from 'types/data';

type ColorType = {
  text: string;
  bg: string;
  border: string;
};
export const getStatusColor = (status: DisplayStatusTypeValues) => {
  const color: ColorType = {
    text: '',
    bg: '',
    border: '',
  };

  switch (status) {
    case 'Pending':
      color.text = colors.primary.main;
      color.bg = colors.primary.statusBg;
      color.border = colors.primary.statusBorder;
      break;
    case 'Partly_Delivered':
      color.text = colors.blue.mid;
      color.bg = colors.blue.faded;
      color.border = colors.blue.statusBorder;
      break;
    case 'Completed':
      color.text = colors.green.main;
      color.bg = colors.green.light;
      color.border = colors.green.faded;
      break;
    case 'Cancelled':
      color.text = colors.red.main;
      color.bg = colors.red.faded;
      color.border = colors.red.light;
      break;

    default:
      color.text = colors.blue.mid;
      color.bg = colors.blue.faded;
      color.border = colors.blue.statusBorder;
      break;
  }

  return color;
};
