import { ZodType, z } from 'zod';

export type EditInventoryFieldType = {
  name: string;
  description: string;
  category: string;
  quantity_in_stock: string;
  amount: string;
  // sale_price: string;
  // date: string;
};

export const EditInventorySchema: ZodType<EditInventoryFieldType> = z.object({
  name: z.string().min(1, 'Name is required'),
  description: z.string(),
  category: z.string().min(1, 'Please specify category'),
  quantity_in_stock: z.string().min(1, 'Enter quantity in stock'),
  amount: z.string().min(1, 'Please specify amount'),
});
