import { Avatar, Box, Divider, Stack } from '@mui/material';
import React, { useMemo } from 'react';

import { Typography } from 'components/shared';
import { useAuthStore } from 'store/auth';
import { colors } from 'theme/color';
import { getNameInitials } from 'utils';

export const SidebarHeader: React.FC = () => {
  const { user } = useAuthStore();

  const profileImage = useMemo(() => {
    if (user) {
      return (
        (user.profilePicture &&
          user.profilePicture.length > 0 &&
          user.profilePicture[0].url) ||
        ''
      );
    }
  }, [user]);
  return (
    <Box
      sx={{
        position: 'sticky',
        top: 0,
        zIndex: 1,
        background: colors.secondary['light'],
      }}
    >
      <Box display='flex' paddingX={4} height={95} alignItems='center' gap={1}>
        <Avatar sx={{ width: 30, height: 30 }}>
          {!profileImage ? (
            <Typography variant='md'>
              {getNameInitials(user?.fullName || user?.email || '')}
            </Typography>
          ) : (
            <img
              src={profileImage}
              alt='user'
              style={{
                borderRadius: '50%',
                width: '100%',
                height: '100%',
                objectFit: 'cover',
              }}
            />
          )}
        </Avatar>
        <Stack gap={0.5}>
          <Typography
            variant='md'
            color={colors.white}
            style={{
              fontSize: 12,
            }}
          >
            {user?.fullName || user?.email || ''}
          </Typography>
          <Typography
            variant='xs'
            color={colors.primary['main']}
            textTransform='capitalize'
          >
            {user?.roleName || 'Admin'}
          </Typography>
        </Stack>
      </Box>
      <Divider sx={{ borderColor: colors.secondary['mid'] }} />
    </Box>
  );
};
