import { CircularProgress, Stack, Typography } from '@mui/material';
import { ChangeEvent, MutableRefObject, useEffect, useRef, useState } from 'react';

import { appAxios } from 'api/axios';
import { ReactComponent as UploadImage } from 'assets/svgs/upload-image.svg';
import { Button } from 'components/shared';
import { colors } from 'theme/color';
import { sendAxiosCatchFeedback, sendFeedback } from 'utils/feedback';
import { ProductInventoryType } from '../data';

type Props = {
  refetch: () => void;
  product: ProductInventoryType;
};

export const ProductImagePicker: React.FC<Props> = ({ product, refetch }) => {
  const fileRef: MutableRefObject<HTMLInputElement | null> = useRef(null);
  const [selectedFile, setSelectedFile] = useState<File | undefined>(undefined);
  const [previewURL, setPreviewURL] = useState(
    product.images && product.images.length > 0 ? product.images[0].url : ''
  );
  const [loading, setLoading] = useState(false);

  const onFileChangeCapture = async (e: ChangeEvent<HTMLInputElement>) => {
    /*Selected files data can be collected here.*/
    if (e.target.files) {
      setSelectedFile(e.target.files[0]);

      // upload file
      await uploadImage(e.target.files[0]);
    }
  };
  const onBtnClick = () => {
    fileRef.current?.click();
  };

  useEffect(() => {
    let objectUrl = '';
    if (selectedFile) {
      // create the preview
      objectUrl = URL.createObjectURL(selectedFile);
      setPreviewURL(objectUrl);
    }
    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);

  const uploadImage = async (file: File) => {
    if (file) {
      try {
        setLoading(true);

        const formData = new FormData();

        formData.append('file', file);

        await appAxios.post(`/upload/upload_products/${product.id}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        sendFeedback('Image updated', 'success');
        refetch();
      } catch (error) {
        sendAxiosCatchFeedback(error);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <>
      <Typography color={colors.black.shade} fontWeight={600}>
        Product Image
      </Typography>
      <Stack
        mt={1}
        p={4}
        alignItems='center'
        textAlign='center'
        justifyContent='center'
        gap={1}
        borderRadius='16px'
        height='244px'
        position='relative'
        sx={{ boxShadow: `0px 4px 25px 0px ${colors.gray.border}` }}
        className='image-container'
      >
        <input
          type='file'
          ref={fileRef}
          onChangeCapture={onFileChangeCapture}
          hidden
          accept='image/png, image/gif, image/jpeg'
        />
        {previewURL ? (
          <>
            <Stack
              position='absolute'
              top={0}
              bottom={0}
              right={0}
              left={0}
              className='image-edit-container'
            >
              {loading ? (
                <CircularProgress />
              ) : (
                <Button
                  onClick={onBtnClick}
                  style={{
                    backgroundColor: colors.white,
                    color: colors.black.main,
                    width: '166px',
                  }}
                >
                  EDIT IMAGE
                </Button>
              )}
            </Stack>
            <img
              src={previewURL}
              alt='product'
              width='100%'
              height='100%'
              style={{
                objectFit: 'cover',
              }}
            />
          </>
        ) : (
          <>
            <UploadImage onClick={onBtnClick} />
            <Stack direction='row' alignItems='center' gap='4px'>
              <Typography color={colors.black.main} variant='md' fontWeight={500}>
                Click
              </Typography>
              <Typography
                color={colors.primary.main}
                onClick={onBtnClick}
                style={{ cursor: 'pointer' }}
              >
                here
              </Typography>
              <Typography color={colors.black.main}>to Upload Images </Typography>
              <br />
            </Stack>
            <Typography color={colors.black.light} variant='sm'>
              Jpeg, png are allowed
            </Typography>
          </>
        )}
      </Stack>
    </>
  );
};
