import { ApolloError } from '@apollo/client';
import { toast } from 'react-toastify';

type Type = 'success' | 'info' | 'warning' | 'error';
type Message = string;

export const sendFeedback = (message: Message, type?: Type) => {
  toast[type || 'info'](message, {
    toastId: 'toastId',
  });
};

export const sendCatchFeedback = (error: ApolloError | any) => {
  const graphQLErrors = error.graphQLErrors;
  const networkError = error.networkError;
  const clientError = error.clientErrors;
  const protocolErrors = error.protocolErrors;

  let errorToShow = '';
  const defaultError = 'Request unsuccessful';

  if (graphQLErrors.length > 0) {
    errorToShow = graphQLErrors[0]?.message || defaultError;
    return toast.error(errorToShow, {
      toastId: 'toastId',
    });
  }
  if (networkError) {
    errorToShow =
      (networkError?.result?.errors && networkError?.result?.errors[0]?.message) ||
      networkError?.message ||
      defaultError;
    return toast.error(errorToShow, {
      toastId: 'toastId',
    });
  }
  if (clientError.length > 0) {
    errorToShow = clientError[0]?.message || defaultError;
    return toast.error(errorToShow, {
      toastId: 'toastId',
    });
  }
  if (protocolErrors.length > 0) {
    errorToShow = protocolErrors[0]?.message || defaultError;
    return toast.error(errorToShow, {
      toastId: 'toastId',
    });
  }
};

export const sendAxiosCatchFeedback = (error: any) => {
  toast.error(
    error.response?.data ? error.response.data.message : 'Request unsuccessful',
    {
      toastId: 'toastId',
    }
  );
};
