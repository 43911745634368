/* eslint-disable import/no-extraneous-dependencies */
// Core Packages
import { ApolloClient, ApolloLink, from, HttpLink, InMemoryCache } from '@apollo/client';
import { RetryLink } from '@apollo/client/link/retry';
import { useAuthStore } from 'store/auth';
import { GRAPHQL_API, REST_API } from 'utils/env';
import { sendFeedback } from 'utils/feedback';
import { getTokenDetails } from 'utils/userSession';

const httpLink = new HttpLink({ uri: GRAPHQL_API });

const signOutUser = () => {
  useAuthStore.getState().signOut();
  sendFeedback('Login to continue', 'info');
  window.location.href = '/sign-in';
};

const authMiddleware = new ApolloLink((operation, forward) => {
  const authToken = getTokenDetails();

  operation.setContext({
    headers: {
      Authorization: authToken ? `Bearer ${authToken?.accessToken}` : '',
      Platform: 'CAP ADMIN',
    },
  });
  return forward(operation);
});

const responseTokenLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((response) => {
    const context = operation.getContext();
    const {
      response: { headers },
    } = context;

    if (headers) {
      const accessToken = headers.get('x-token');
      const refreshToken = headers.get('x-refresh-token');

      if (accessToken && refreshToken) {
        useAuthStore.getState().setToken({ accessToken, refreshToken });
      }
    }

    return response;
  });
});

const link = new RetryLink({
  delay: {
    initial: 300,
    max: Infinity,
    jitter: true,
  },
  attempts: {
    max: 5,
    retryIf: async (error) => {
      const refreshToken = getTokenDetails()?.refreshToken;
      // const accessToken = getTokenDetails()?.accessToken;
      // let isExpired = false;
      // const decodedAccessToken: UserTokenType | null = decodeToken(accessToken!);

      // if (decodedAccessToken) {
      //   const expiryDate = decodedAccessToken.exp;
      //   // Multiply by 1000 because the timestamp is a unix value
      //   isExpired = new Date(expiryDate * 1000).getTime() < new Date().getTime();
      // }

      if (
        // isExpired ||
        error.statusCode === 401 ||
        error?.response?.errors?.[0]?.message ===
          'Context creation failed: Expired or invalid JWT token'
      ) {
        try {
          // Fetch the current refresh token from storage

          // If there's no refresh token, log out the user
          if (!refreshToken) {
            signOutUser();
            return false;
          }

          // Make a request to refresh the token
          const refreshResponse = await fetch(`${REST_API}/auth/refresh-token`, {
            method: 'POST',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              refreshToken,
            }),
          });

          // Extract the new access token from the response
          const newAccessToken = refreshResponse.headers.get('x-token');

          // If the token refresh is successful, save the new tokens
          if (newAccessToken) {
            useAuthStore
              .getState()
              .setToken({ accessToken: newAccessToken, refreshToken }); // Store new tokens
            return true; // Retry the request with the new token
          } else {
            // If the refresh token is expired or invalid, and the refresh request fails, log out the user
            signOutUser();
            return false;
          }
        } catch (refreshError) {
          // Handle errors during the token refresh process
          signOutUser();

          return false;
        }
      }
      return false;
    },
  },
});

const client = new ApolloClient({
  link: from([link, authMiddleware, responseTokenLink, httpLink]),
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'network-only',
    },
    query: {
      fetchPolicy: 'network-only',
    },
  },
});

export default client;
