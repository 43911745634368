import { Box, Menu, MenuItem, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';

import dropdownIcon from 'assets/svgs/chevron-down.svg';
import { InventoryFilterType } from 'components/inventory/data';
import { Button } from 'components/shared';
import { colors } from 'theme/color';
import { SortFilterType } from 'types/data';

type Props = {
  label: string;
  labelValue: string;
  values: SortFilterType[] | InventoryFilterType[];
  selectedValue: SortFilterType | InventoryFilterType;
  onSelectValue: (value: SortFilterType | InventoryFilterType) => void;
};

export const FilterMenu: React.FC<Props> = ({
  label,
  selectedValue,
  values,
  labelValue,
  onSelectValue,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <Box>
      <Button
        aria-controls={open ? 'filter-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        variant='text'
      >
        <Stack direction='row' alignItems='center' spacing={1}>
          <Typography variant='md' color={colors.black.main} fontWeight={500}>
            {label}
          </Typography>
          <Typography
            variant='md'
            color={colors.black.light}
            fontWeight={500}
            sx={{
              minWidth: 'fit-content',
            }}
          >
            {selectedValue.label || labelValue}
          </Typography>
          <img src={dropdownIcon} alt='dropdown' />
        </Stack>
      </Button>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {values.map((item) => (
          <MenuItem
            onClick={() => {
              handleClose();
              onSelectValue(item);
            }}
            key={item.value}
          >
            {item.label}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};
