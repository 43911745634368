import { AuthTokenType } from 'types/auth';
import {
  removeSessionDetails,
  removeTokenDetails,
  storeSessionDetails,
  storeTokenDetails,
} from 'utils/userSession';
import { create } from 'zustand';

/** SAMPLE  AUTH STORE CONFIG **/

type AuthState = {
  user: UserType | null;
  setUser: (user: UserType) => void;
  token: AuthTokenType | undefined;
  setToken: (token: AuthTokenType) => void;
  signOut: () => void;
};

export const useAuthStore = create<AuthState>()((set) => ({
  user: null,
  token: undefined,
  setUser: (user) => {
    set({ user });
    storeSessionDetails(user);
  },
  setToken: (token) => {
    set({ token });
    storeTokenDetails(token);
  },
  signOut: () => {
    set({
      user: null,
      token: undefined,
    });
    removeTokenDetails();
    removeSessionDetails();
  },
}));
