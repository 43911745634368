import { Button } from 'components/shared';
import React from 'react';

import { ReactComponent as LeftArrow } from 'assets/svgs/arrow-left.svg';
import { useNavigate } from 'react-router-dom';
import { colors } from 'theme/color';

type Props = {
  navigateTo?: string;
  text?: string;
};

export const BackButton: React.FC<Props> = ({ navigateTo, text }) => {
  const navigate = useNavigate();

  const navigateToRoute = () => {
    if (navigateTo) {
      return navigate(navigateTo);
    } else {
      return navigate(-1);
    }
  };

  return (
    <Button
      variant='text'
      startIcon={<LeftArrow />}
      onClick={navigateToRoute}
      sx={{
        width: 'fit-content',
        color: colors.black.main,
        marginBottom: '10px',
      }}
    >
      {text || 'Back'}
    </Button>
  );
};
