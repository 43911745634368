import { Box, Tab as MuiTab, Tabs as MuiTabs, TabsProps } from '@mui/material';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { colors } from 'theme/color';

type Props = {
  labels: string[];
  panels: React.ReactNode[];
  index?: number;
  setIndex?: Dispatch<SetStateAction<number>>;
} & TabsProps;

export const Tabs: React.FC<Props> = ({
  labels,
  panels,
  index = 0,
  setIndex,
  ...rest
}) => {
  const [value, setValue] = useState(index || 0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    if (setIndex) {
      return setIndex(newValue);
    }

    return setValue(newValue);
  };
  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: colors.neutral.light }}>
        <MuiTabs
          value={index || value}
          onChange={handleChange}
          sx={{
            '& .MuiTabs-indicator': {
              backgroundColor: colors.primary.main,
            },
          }}
          {...rest}
        >
          {labels.map((label) => (
            <MuiTab
              label={label}
              key={label}
              sx={{
                maxWidth: 'fit-content',
                textTransform: 'none',
                fontWeight: 500,
                fontSize: 14,
                '&.Mui-selected': {
                  color: colors.primary.main,
                },
                '&.Mui-focusVisible': {
                  backgroundColor: colors.primary.light,
                },
              }}
            />
          ))}
        </MuiTabs>
      </Box>

      {/* View active panel */}
      <Box py={2}>{panels[index || value]}</Box>
    </Box>
  );
};
