import Box from '@mui/material/Box';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { Typography } from 'components/shared';
import React, { useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { colors } from 'theme/color';

export const Breadcrumb: React.FC = () => {
  let currentRoute = useLocation();

  const routeName = useMemo(
    () => currentRoute.pathname?.split('/')[1] || '',
    [currentRoute]
  );

  return (
    <Box>
      <Breadcrumbs aria-label='breadcrumb'>
        <Link color='inherit' to='/'>
          <Typography color={colors.black.light}>
            {routeName ? 'Admin' : 'Dashboard'}
          </Typography>
        </Link>
        {routeName && (
          <Typography color={colors.black.main} textTransform='capitalize'>
            {routeName}
          </Typography>
        )}
      </Breadcrumbs>
    </Box>
  );
};
