export const getNameInitials = (name: string) => {
  return name
    .split(' ')
    .map((n) => n[0])
    .join('');
};

export const removeUnderscore = (str: string) => {
  if (str?.includes('_')) {
    const words = str.split('_');
    const capitalizedWords = words.map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1)
    );
    return capitalizedWords.join(' ');
  } else if (str?.includes(' ')) {
    const words = str.split(' ');
    const capitalizedWords = words.map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1)
    );
    return capitalizedWords.join(' ');
  } else {
    return str?.length > 1
      ? str?.charAt(0).toUpperCase() + str?.slice(1)
      : str?.charAt(0).toUpperCase();
  }
};

export const formatNumberToNaira = (number: number | string) =>
  new Intl.NumberFormat('en-NG', {
    style: 'currency',
    currency: 'NGN',
    maximumFractionDigits: 0,
  }).format(Number(number));
