import { createTheme } from '@mui/material/styles';

import { colors } from './color';
import { typography } from './typography';

const theme = createTheme({
  typography,
  components: {
    MuiButton: {
      styleOverrides: {
        outlined: {
          color: colors.primary['main'],
          borderColor: colors.primary['main'],
          '&:hover': {
            color: colors.white,
            background: colors.primary['main'],
            borderColor: colors.primary['main'],
          },
          '&.MuiButton-outlined.Mui-disabled': {
            background: colors.primary['light'],
          },
        },
        contained: {
          color: colors.white,
          backgroundColor: colors.primary['main'],
          '&:hover': {
            background: colors.secondary['light'],
          },
          '&.MuiButton-contained.Mui-disabled': {
            background: colors.primary['light'],
          },
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        select: {
          backgroundColor: colors.white,
          border: `1px solid ${colors.neutral.light}`,
          color: colors.black.faded,
          fontSize: 14,
          fontWeight: 400,
          borderRadius: 30,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },
        selectRoot: {
          width: 60,
          borderRadius: 30,
          height: 36,
        },
        selectLabel: {
          color: colors.gray.light,
          fontSize: 14,
          fontWeight: 400,
        },
      },
    },
    MuiPagination: {
      styleOverrides: {
        ul: {
          '& .MuiPaginationItem-root': {
            color: colors.black.faded,
            fontSize: 14,
            fontWeight: 400,
          },
          '& .Mui-selected': {
            backgroundColor: `${colors.black.main} !important`,
            color: `${colors.white} !important`,
          },
        },
        root: {
          backgroundColor: colors.white,
          borderRadius: '22.5px',
          paddingInline: 16,
          paddingBlock: 4,
          marginRight: 16,
        },
      },
    },
  },
});

export default theme;
