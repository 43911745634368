import axios from 'axios';
import { REST_API } from 'utils/env';
import { getTokenDetails } from 'utils/userSession';

export const appAxios = axios.create({
  headers: {
    'Content-Type': 'application/json',
    Platform: 'CAP ADMIN',
  },
  baseURL: REST_API,
});

appAxios.interceptors.request.use(
  (config) => {
    const sessionToken = getTokenDetails();

    const token = sessionToken?.accessToken;

    if (token) {
      config.headers.Authorization = 'Bearer ' + token;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
