import { Box } from '@mui/material';
import React from 'react';

import BellIcon from 'assets/svgs/bell.svg';
// import ChatIcon from 'assets/svgs/chat.svg';
import { Badge } from 'components/shared';
import { colors } from 'theme/color';

import { RoutePath } from 'constants/routes-path';
import { Link } from 'react-router-dom';
import { Breadcrumb } from './breadcrumb';

export const Header: React.FC = () => {
  return (
    <Box
      component='header'
      sx={{
        height: 75,
        position: 'sticky',
        top: 0,
        background: colors.white,
        boxShadow: `1px 1px 0 0 ${colors.secondary['rgba']}`,
        paddingX: 1,
        zIndex: 10,
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Box
        maxWidth={1400}
        marginX='auto'
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <Breadcrumb />
        <Box sx={{ display: 'flex', gap: 2, paddingRight: 1 }}>
          <Link to={RoutePath.NOTIFICATIONS}>
            <Badge count={0} label='Notifications' icon={BellIcon} responsive />
          </Link>
          {/* <Link to={RoutePath.MESSAGES}>
            <Badge count={0} label='Messages' icon={ChatIcon} responsive />
          </Link> */}
        </Box>
      </Box>
    </Box>
  );
};
