export const RoutePath = {
  DASHBOARD: '/',
  INVENTORY: '/inventory',
  ADD_INVENTORY: '/inventory/new-inventory',
  PARTNERS: '/partners',
  ORDER_MANAGEMENT: '/order-management',
  TRANSACTIONS: '/transactions',
  REPS: '/reps',
  CENTERS: '/centers',
  REPORTS: '/reports',
  AUDIT_TRAIL: '/audit-trail',
  AUDIT_TRAIL_ADMIN: '/audit-trail/admin',
  AUDIT_TRAIL_PARTNER: '/audit-trail/partner',
  AUDIT_TRAIL_REP: '/audit-trail/rep',
  MESSAGES: '/messages',
  SETTINGS: '/settings',
  SIGN_IN: '/sign-in',
  SET_PASSWORD: '/set-password',
  FORGOT_PASSWORD: '/forgot-password',
  VERIFY_RESET_OTP: '/forgot-password/verify',
  UPDATE_RESET_PASSWORD: '/forgot-password/update',
  NOTIFICATIONS: '/notifications',
} as const;
