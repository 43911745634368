import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import React from 'react';

import BusinessPlan from 'assets/Images/business-plan.png';
import { ReactComponent as Logo } from 'assets/svgs/logo.svg';
import { ProtectedRoutes } from 'middleware';
import { colors } from 'theme/color';

type Props = {
  children: React.ReactNode;
};

const SetPasswordLayout = ({ children }: Props) => {
  return (
    <ProtectedRoutes>
      <Box
        width='40%'
        height='100vh'
        position='fixed'
        sx={{ background: colors.secondary['light'] }}
      >
        <Stack justifyContent='space-between' height='100%' paddingX={5} paddingY={2}>
          <Logo width={100} />
          <Stack alignItems='center'>
            <img src={BusinessPlan} alt='business-plan' width='500px' />
          </Stack>
          <Typography
            color={colors.gray['main']}
            variant='h3'
            textAlign='center'
            paddingBottom={20}
          >
            Admin Portal
          </Typography>
        </Stack>
      </Box>

      {/* LAYOUT CONTENTS */}
      <Box
        display='flex'
        width='60%'
        position='relative'
        minHeight='100vh'
        marginLeft='40%'
      >
        {/* LAYOUT FORM COMPONENTS */}
        <Box
          width='100%'
          maxWidth={800}
          marginX='auto'
          alignItems='center'
          justifyContent='center'
        >
          {children}
        </Box>
        {/* END */}
      </Box>
    </ProtectedRoutes>
  );
};

export default SetPasswordLayout;
