import {
  endOfDay,
  endOfMonth,
  endOfQuarter,
  endOfWeek,
  endOfYear,
  format,
  startOfDay,
  startOfMonth,
  startOfQuarter,
  startOfWeek,
  startOfYear,
} from 'date-fns';
import { ReportFilterState } from 'types/data';
// export const formatDate = (date: Date): string => {
//   return new Intl.DateTimeFormat('en-US', {
//     year: 'numeric',
//     month: '2-digit',
//     day: '2-digit',
//     hour: '2-digit',
//     minute: '2-digit',
//   }).format(date);
// };

export const formatDate = (date: Date, st = true) => {
  const d = new Date(date);
  let dd;
  if (st) {
    const monthNames = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];
    const month = monthNames[d.getMonth()];
    const day = d.getDate();
    const year = d.getFullYear();
    dd = [day, month, year].join(' ');
  } else {
    const month = d.getMonth() + 1 < 10 ? `0${d.getMonth() + 1}` : d.getMonth() + 1;
    const day = d.getDate() < 10 ? `0${d.getDate()}` : d.getDate();
    const year = d.getFullYear();
    dd = [year, month, day].join('-');
  }
  return dd;
};

export const addOneDayToDate = (date: Date, format?: boolean) => {
  const newDate = new Date(date);
  const currentDate = new Date();
  newDate.setDate(newDate.getDate() + 1);
  currentDate.setDate(currentDate.getDate() + 1);
  if (newDate > currentDate) {
    return format ? formatDate(currentDate) : currentDate;
  }
  return format ? formatDate(newDate) : newDate;
};

export const formatTime = (date: Date): string => {
  return date
    ? new Date(date).toLocaleTimeString('en-US', {
        hour: '2-digit',
        hour12: true,
        minute: '2-digit',
      })
    : '';
};

export function getDateRangeForReportFilter(
  selectedFilter: ReportFilterState['selectedFilter']
): { fullString: string; startDate: string; endDate: string } {
  const now: Date = new Date();
  let startDate: Date;
  let endDate: Date;

  switch (selectedFilter) {
    case 'day':
      startDate = startOfDay(now);
      endDate = endOfDay(now);
      break;
    case 'week':
      startDate = startOfWeek(now, { weekStartsOn: 0 }); // Week starts on Sunday
      endDate = endOfWeek(now, { weekStartsOn: 0 });
      break;
    case 'month':
      startDate = startOfMonth(now);
      endDate = endOfMonth(now);
      break;
    case 'quarter':
      startDate = startOfQuarter(now);
      endDate = endOfQuarter(now);
      break;
    case 'year':
      startDate = startOfYear(now);
      endDate = endOfYear(now);
      break;
    default:
      // Use month as default
      startDate = startOfMonth(now);
      endDate = endOfMonth(now);
      break;
  }

  const formatDate = (date: Date): string => format(date, 'do MMMM yyyy');

  return {
    fullString: `${formatDate(startDate)} - ${formatDate(endDate)}`,
    startDate: formatDate(startDate),
    endDate: formatDate(endDate),
  };
}
