import { decrypt, encrypt } from 'libs/encryption';
import { AuthTokenType } from 'types/auth';
import { SESSION_NAME, TOKEN_NAME } from './env';

export const getSessionDetails = () => {
  let sessionDetails =
    typeof window !== 'undefined' ? window.localStorage.getItem(SESSION_NAME!) : null;
  if (sessionDetails) {
    sessionDetails = decrypt(sessionDetails);
    sessionDetails = JSON.parse(sessionDetails as string);
  }

  return sessionDetails as UserType | null;
};

export const storeSessionDetails = (sessionDetails: UserType) => {
  const encryptedSession = encrypt(JSON.stringify(sessionDetails));
  localStorage.setItem(SESSION_NAME!, encryptedSession);
  return true;
};

export const removeSessionDetails = () => {
  localStorage.removeItem(SESSION_NAME!);
  return true;
};

export const getTokenDetails = () => {
  let token =
    typeof window !== 'undefined' ? window.localStorage.getItem(TOKEN_NAME!) : null;
  if (token) {
    token = decrypt(token);
    token = JSON.parse(token as string);
  }

  return token as AuthTokenType | null;
};

export const storeTokenDetails = (token: AuthTokenType) => {
  const encryptedSession = encrypt(JSON.stringify(token));
  localStorage.setItem(TOKEN_NAME!, encryptedSession);
  return true;
};

export const removeTokenDetails = () => {
  localStorage.removeItem(TOKEN_NAME!);
  return true;
};
