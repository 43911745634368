import { RoutePath } from "./routes-path";

export const SIDEBAR = {
    main: [
        {
            name: 'Dashboard',
            icon: 'HomeIcon',
            link: RoutePath.DASHBOARD
        },
        {
            name: 'Order Management',
            icon: 'ReceiptIcon',
            link: RoutePath.ORDER_MANAGEMENT
        },
        {
            name: 'Partners',
            icon: 'UsersIcon',
            link: RoutePath.PARTNERS
        },
        {
            name: 'Reps',
            icon: 'UserIcon',
            link: RoutePath.REPS
        },
        {
            name: 'Centers',
            icon: 'CenterIcon',
            link: RoutePath.CENTERS
        },
        {
            name: 'Inventory',
            icon: 'SplitArrowIcon',
            link: RoutePath.INVENTORY
        },
        {
            name: 'Reports',
            icon: 'AnchorIcon',
            link: RoutePath.REPORTS
        },
        {
            name: 'Audit Trail',
            icon: 'AuditTrailIcon',
            link: RoutePath.AUDIT_TRAIL
        },

    ],
    admin: [
        // {
        //     name: 'Messages',
        //     icon: 'MessageIcon',
        //     link: RoutePath.MESSAGES
        // },
        {
            name: 'Settings',
            icon: 'SettingsIcon',
            link: RoutePath.SETTINGS
        },
        {
            name: 'Logout',
            icon: 'LogoutIcon'
        },
    ]
}
