import { ZodType, z } from 'zod';

export type EditInventoryFieldType = {
  name: string;
  description: string;
  category: string;
  // quantity_in_stock: string;
  amount: string;
  // sale_price: string;
  // date: string;
};

export const EditInventorySchema: ZodType<EditInventoryFieldType> = z.object({
  name: z.string().min(1, 'Name is required'),
  description: z.string().min(1, 'Description is required'),
  category: z.string().min(1, 'Please specify category'),
  // quantity_in_stock: z.string().min(1, 'Enter quantity in stock'),
  amount: z.string().min(1, 'Please specify amount'),
});

export type AddInventoryFieldType = {
  sap_id: string;
  product_name: string;
  product_price: number;
  price_unit: string;
  quantity_unit: string;
  product_per_item: number;
  product_validity_from: string;
  product_validity_to: string;
  minimum_req_qty: string;
  description: string;
  brand: string;
  category: string;
  product_line: string;
  class_type: string;
  color: string;
  pack_size: string;
};

export const AddInventorySchema: ZodType<AddInventoryFieldType> = z.object({
  sap_id: z.string().min(1, 'Required'),
  product_name: z.string().min(1, 'Required'),
  product_price: z.number().min(1, 'Required'),
  price_unit: z.string().min(1, 'Required'),
  quantity_unit: z.string().min(1, 'Required'),
  product_per_item: z.number().min(1, 'Required'),
  product_validity_from: z.string().min(1, 'Required'),
  product_validity_to: z.string().min(1, 'Required'),
  minimum_req_qty: z.string().min(1, 'Required'),
  description: z.string().min(1, 'Required'),
  brand: z.string().min(1, 'Required'),
  category: z.string().min(1, 'Please specify category'),
  product_line: z.string().min(1, 'Required'),
  class_type: z.string().min(1, 'Required'),
  color: z.string().min(1, 'Required'),
  pack_size: z.string().min(1, 'Required'),
});
