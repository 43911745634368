import { SvgIconTypeMap } from '@mui/material';
import MSvgIcon from '@mui/material/SvgIcon';
import React from 'react';

import { ReactComponent as AnchorIcon } from 'assets/svgs/anchor.svg';
import { ReactComponent as AuditTrailIcon } from 'assets/svgs/audit-trail.svg';
import { ReactComponent as BellIcon } from 'assets/svgs/bell.svg';
import { ReactComponent as CenterIcon } from 'assets/svgs/center.svg';
import { ReactComponent as ChatIcon } from 'assets/svgs/chat.svg';
import { ReactComponent as HomeIcon } from 'assets/svgs/home.svg';
import { ReactComponent as LogoutIcon } from 'assets/svgs/logout.svg';
import { ReactComponent as MessageIcon } from 'assets/svgs/message.svg';
import { ReactComponent as PlusIcon } from 'assets/svgs/plus-circle.svg';
import { ReactComponent as ReceiptIcon } from 'assets/svgs/receipt.svg';
import { ReactComponent as SettingsIcon } from 'assets/svgs/settings.svg';
import { ReactComponent as SplitArrowIcon } from 'assets/svgs/split-arrow.svg';
import { ReactComponent as UserIcon } from 'assets/svgs/user.svg';
import { ReactComponent as UsersIcon } from 'assets/svgs/users.svg';

type Props = SvgIconTypeMap['props'] & {
  name: string;
};

// THIS OBJECT WILL HOLD ALL THE CUSTOM ICONS WE USED IN THE APP.
const SVG_ICONS: { [key: string]: React.ElementType } = {
  HomeIcon,
  LogoutIcon,
  SettingsIcon,
  AuditTrailIcon,
  CenterIcon,
  UsersIcon,
  UserIcon,
  MessageIcon,
  SplitArrowIcon,
  ReceiptIcon,
  AnchorIcon,
  ChatIcon,
  BellIcon,
  PlusIcon,
};

export const SvgIcon: React.FC<Props> = ({ name, ...rest }) => {
  const Component = SVG_ICONS[name];

  if (!Component) return null;

  return <MSvgIcon component={Component} {...rest} />;
};
