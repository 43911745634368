import CryptoJS from 'crypto-js';
import { ENCRYPTION_KEY } from 'utils/env';

let encryptionKey = ENCRYPTION_KEY as string;

export const encrypt = (value: string) => {
  const encrypted = CryptoJS.AES.encrypt(value, encryptionKey).toString();
  return encrypted;
};

export const decrypt = (value: string) => {
  const bytes = CryptoJS.AES.decrypt(value, encryptionKey);
  return bytes.toString(CryptoJS.enc.Utf8);
};
