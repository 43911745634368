import { gql } from '@apollo/client';

export const TOGGLE_OUT_OF_STOCK = gql`
  mutation TOGGLE_OUT_OF_STOCK($data: OutOfStockInput!) {
    toggleOutOfStock(data: $data) {
      data {
        id
      }
      status
      message
    }
  }
`;

export const TOGGLE_INVENTORY_LOCK = gql`
  mutation TOGGLE_INVENTORY_LOCK($data: LockedInput!) {
    toggleLocked(data: $data) {
      data {
        id
      }
      status
      message
    }
  }
`;

export const DELETE_INVENTORY = gql`
  mutation DELETE_INVENTORY($data: FavouriteMutationInput!) {
    deleteCatalogue(data: $data) {
      data {
        id
      }
      status
      message
    }
  }
`;

export const UPDATE_INVENTORY = gql`
  mutation UPDATE_INVENTORY($data: UpdateCatalogueInput!) {
    updateCatalogue(data: $data) {
      data {
        id
      }
      status
      message
    }
  }
`;
