import { RouterProvider } from 'react-router-dom';

import { useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import { useAuthStore } from 'store/auth';
import { getSessionDetails, getTokenDetails } from 'utils/userSession';
import { router } from './routes';

function App() {
  // Update user state with logged in token info
  const { setToken, setUser } = useAuthStore();

  useEffect(() => {
    const tokenDetails = getTokenDetails();
    const userDetails = getSessionDetails();

    if (
      tokenDetails &&
      tokenDetails.accessToken &&
      Object.keys(tokenDetails.accessToken).length
    ) {
      setToken(tokenDetails);
    }
    if (userDetails && Object.keys(userDetails).length) {
      setUser(userDetails);
    }
  }, [setToken, setUser]);

  return (
    <>
      <RouterProvider router={router} />
      <ToastContainer
        style={{
          fontSize: 16,
          zIndex: 1500,
          fontFamily: 'Public Sans, Serif',
        }}
        theme='colored'
        autoClose={5000}
        position='top-right'
        hideProgressBar={true}
        closeOnClick={true}
      />
    </>
  );
}
export default App;
