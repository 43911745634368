import { Box, IconButton, Menu, MenuItem, Typography } from '@mui/material';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import menuIcon from 'assets/svgs/menu.svg';
import { RoutePath } from 'constants/routes-path';
import { colors } from 'theme/color';

import { ProductInventoryType } from '../data';
import { LockInventoryToggle } from './menu-controls/LockInventoryToggle';
import { OutOfStockToggle } from './menu-controls/OutOfStockToggle';

type Props = {
  refetch: () => void;
  openDeleteModal: () => void;
  setSelectedProduct: Dispatch<SetStateAction<ProductInventoryType | undefined>>;
  product: ProductInventoryType;
};

export const InventoryMenu: React.FC<Props> = ({
  product,
  refetch,
  setSelectedProduct,
  openDeleteModal,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const navigate = useNavigate();

  return (
    <Box>
      <IconButton
        aria-controls={open ? 'control-menu' : undefined}
        aria-haspopup='true'
        aria-label='options'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        sx={{
          width: '27.2px',
          height: '32.2px',
          backgroundColor: colors.neutral.mid,
          borderRadius: '4px',
        }}
      >
        <img src={menuIcon} alt='menu' />
      </IconButton>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <OutOfStockToggle product={product} handleClose={handleClose} refetch={refetch} />
        <LockInventoryToggle
          product={product}
          handleClose={handleClose}
          refetch={refetch}
        />

        <MenuItem
          onClick={() => {
            handleClose();
            setSelectedProduct(product);
            openDeleteModal();
          }}
        >
          <Typography>Delete inventory</Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            navigate(`${RoutePath.INVENTORY}/${product.id}`);
          }}
        >
          <Typography>Edit Inventory</Typography>
        </MenuItem>
      </Menu>
    </Box>
  );
};
