import { Box, Divider, Stack, Typography } from '@mui/material';
import React, { Dispatch, SetStateAction } from 'react';

import increaseIcon from 'assets/svgs/arrow_increase.svg';
import { Card } from 'components/shared/card';
import { colors } from 'theme/color';

import { getNameInitials } from 'utils';
import { ProductInventoryType } from '../data';
import { InventoryMenu } from './inventory-menu';

type Props = {
  refetch: () => void;
  openDeleteModal: () => void;
  product: ProductInventoryType;
  setSelectedProduct: Dispatch<SetStateAction<ProductInventoryType | undefined>>;
};
export const InventoryCard: React.FC<Props> = ({
  product,
  refetch,
  setSelectedProduct,
  openDeleteModal,
}) => {
  return (
    <Card>
      <Stack
        direction='row'
        alignItems='flex-start'
        justifyContent={'space-between'}
        spacing={1}
        mb={5}
      >
        <Stack direction='row' spacing={3} alignItems='flex-start'>
          <Box
            minWidth='73px'
            minHeight='73px'
            display='flex'
            justifyContent='center'
            alignItems='center'
            bgcolor={colors.gray.plain}
            borderRadius='8px'
          >
            <Typography variant='lg' fontWeight='600'>
              {getNameInitials(product.name)}
            </Typography>
          </Box>

          <Stack spacing={1}>
            <Typography variant='md' fontWeight={600}>
              {product.name}
            </Typography>
            <Typography variant='sm' color={colors.black.light} fontWeight={400}>
              {product.description || product.product_line}
            </Typography>
            <Typography variant='sm' color={colors.black.mid} fontWeight={600}>
              {product.category}
            </Typography>
            <Typography variant='md' fontWeight={700}>
              {product.amount}
            </Typography>
          </Stack>
        </Stack>
        <InventoryMenu
          product={product}
          refetch={refetch}
          setSelectedProduct={setSelectedProduct}
          openDeleteModal={openDeleteModal}
        />
      </Stack>
      <Stack
        direction='row'
        alignItems='center'
        justifyContent='space-between'
        spacing={1}
      >
        <Typography variant='md' fontWeight={500}>
          Sales
        </Typography>
        <Stack direction='row' alignItems={'center'} spacing={1}>
          <img
            src={
              increaseIcon
              // product.amount_sold > product.quantity_in_stock
              //   ? increaseIcon
              //   : decreaseIcon
            }
            alt='sales direction'
          />
          <Typography
            variant='md'
            fontWeight={600}
            color={
              colors.green.main
              // product.amount_sold > product.quantity_in_stock
              //   ? colors.green.main
              //   : colors.red.main
            }
          >
            {product.amount_sold}
          </Typography>
        </Stack>
      </Stack>
      <Divider
        sx={{
          marginBlock: 1,
          opacity: 0.6,
        }}
      />
      <Stack
        direction='row'
        alignItems='center'
        justifyContent='space-between'
        spacing={1}
      >
        <Typography variant='md' fontWeight={500}>
          Remaining Products
        </Typography>
        <Stack direction='row' alignItems={'center'} spacing={1}>
          <Box
            width='52px'
            height='4px'
            bgcolor={colors.neutral.mid}
            borderRadius={'8px'}
          >
            <Box
              width={
                (product.quantity_in_stock /
                  (product.amount_sold + product.quantity_in_stock)) *
                  100 +
                '%'
              }
              height='4px'
              bgcolor={
                product.out_of_stock_status === 'inactivate'
                  ? colors.green.main
                  : colors.red.main
              }
              borderRadius={'8px'}
            ></Box>
          </Box>

          <Typography
            variant='md'
            fontWeight={600}
            color={
              product.out_of_stock_status === 'inactivate'
                ? colors.green.main
                : colors.red.main
            }
          >
            {product.quantity_in_stock}
          </Typography>
        </Stack>
      </Stack>
    </Card>
  );
};
