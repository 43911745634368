import { Box, FormLabel, MenuItem, Select, SelectProps, Typography } from '@mui/material';
import React from 'react';
import { FieldError } from 'react-hook-form';

import { colors } from 'theme/color';

type InputType = Omit<SelectProps, 'error'>;

type Props = InputType & {
  label?: string;
  required?: boolean;
  watchValue?: string;
  error?: FieldError;
  containerWidth?: string;
  options: { label: string; value: string }[];
  marginTop?: number;
  padding?: number;
  hint?: string;
};

export const FormSelect = React.forwardRef(
  (
    {
      label,
      watchValue,
      required = false,
      error,
      containerWidth = 'fit-contents',
      options,
      marginTop = 2,
      padding = 2,
      hint,
      ...resProps
    }: Props,
    ref
  ) => {
    return (
      <Box marginTop={marginTop} width={containerWidth} mb={1}>
        {label && (
          <FormLabel sx={{ fontWeight: 600, color: '' }} htmlFor={label}>
            {label}{' '}
            {required ? (
              <Box component='span' color={colors.red['main']}>
                *
              </Box>
            ) : null}
          </FormLabel>
        )}
        <Select
          ref={ref}
          sx={{
            marginTop: 1,
            minWidth: '200px',
            height: 45,
            paddingX: padding,
            ...(error?.message && {
              border: `1px solid ${colors.red['main']}`,
            }),
            borderRadius: '8px',
          }}
          id={label}
          fullWidth
          {...resProps}
        >
          {options.map((item) => (
            <MenuItem value={item.value}>{item.label}</MenuItem>
          ))}
        </Select>
        {hint && (
          <Box mt='4px'>
            <Typography variant='sm' color={colors.black['main']}>
              {hint}
            </Typography>
          </Box>
        )}
        <Box mt='4px'>
          {error?.message && (
            <Typography variant='md' color={colors.red['main']}>
              {error?.message}
            </Typography>
          )}
        </Box>
      </Box>
    );
  }
);
