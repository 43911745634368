import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import React, { Dispatch, ReactNode, SetStateAction } from 'react';

import { colors } from 'theme/color';
import { TableColumnType, TableRowType } from 'types/table';

import { Pagination } from '../shared/pagination';
import { SortableHeader } from './sortable-header';
import { TableRowRenderer } from './table-row-renderer';

type Props = {
  columns: TableColumnType[];
  rows: TableRowType[];
  tableKey?: string;
  size?: number;
  onSizeChange?: Dispatch<SetStateAction<number>>;
  currentPage: number;
  totalItems: number;
  onPageChange: Dispatch<SetStateAction<number>>;
  setSortBy?: Dispatch<SetStateAction<string>>;
  sortBy?: string;
  showPagination?: boolean;
  rowRenderer?: ReactNode;
};

export const DataTable: React.FC<Props> = ({
  columns = [],
  rows = [],
  tableKey = '',
  size,
  onSizeChange,
  currentPage,
  totalItems,
  onPageChange,
  setSortBy = () => {},
  sortBy,
  showPagination = true,
  rowRenderer,
}) => {
  const pagesCount = Math.ceil(totalItems / (size || 1));

  return (
    <>
      <TableContainer
        sx={{
          flex: 1,
          backgroundColor: colors.white,
        }}
      >
        <Table>
          <TableHead>
            <TableRow>
              {columns.map(
                (col) =>
                  !col.isHidden && (
                    <TableCell
                      key={col.title}
                      sx={{
                        fontSize: 12,
                        color: colors.black.main,
                        fontWeight: 600,
                        backgroundColor: colors.neutral.main,
                        textTransform: 'uppercase',
                        padding: '8px 16px',
                        borderRight: `1px solid ${colors.gray.mid}`,
                      }}
                    >
                      <SortableHeader
                        setSortBy={setSortBy}
                        sortBy={sortBy}
                        column={col}
                      />
                    </TableCell>
                  )
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {totalItems > 0 && rowRenderer}
            {totalItems > 0 ? (
              rows.map((row, index) => (
                <TableRowRenderer
                  row={{ ...row, index }}
                  columns={columns}
                  index={index}
                  key={index}
                />
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={columns.length || 1}>
                  <Box p={4} display='flex' justifyContent='center' alignItems={'center'}>
                    <Typography fontWeight={600} fontSize={16}>
                      No data found
                    </Typography>
                  </Box>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {showPagination && (
        <Pagination
          page={currentPage}
          totalItems={totalItems}
          count={pagesCount || 1}
          size={size}
          onSizeChange={onSizeChange}
          onChange={onPageChange}
        />
      )}
    </>
  );
};
