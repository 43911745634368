import { Box, Typography } from '@mui/material';
import React from 'react';

import { colors } from 'theme/color';

type Props = {
  count: number;
  icon: string;
  label?: string;
  responsive?: boolean;
};

export const Badge: React.FC<Props> = ({ count, icon, label, responsive = false }) => {
  return (
    <Box display='flex' gap={1} alignItems='center'>
      <Box position='relative' sx={{ cursor: 'pointer' }}>
        {count > 0 && (
          <Box
            sx={{
              position: 'absolute',
              minWidth: '10px',
              height: '10px',
              background: colors.red['main'],
              right: 0,
              fontSize: '8px',
              padding: '1px',
              textAlign: 'center',
              borderRadius: '50%',
              color: colors.white,
              fontWeight: 'bold',
            }}
          >
            {count}
          </Box>
        )}
        <img src={icon} alt={label} />
      </Box>
      <Typography
        sx={{
          display: {
            xs: responsive ? 'none' : 'block',
            md: 'block',
          },
        }}
      >
        {label}
      </Typography>
    </Box>
  );
};
