import { alpha, Switch as MuiSwitch, styled, SwitchProps } from '@mui/material';
import React from 'react';

import { colors } from 'theme/color';

type Props = {
  checked: boolean;
} & SwitchProps;

const StyledSwitch = styled(MuiSwitch)(({ theme }) => ({
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: colors.primary.main,
    '&:hover': {
      backgroundColor: alpha(colors.primary.main, 40),
    },
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: colors.primary.main,
  },
}));

export const Switch: React.FC<Props> = ({ checked, ...rest }) => {
  return <StyledSwitch size='small' checked={checked} {...rest} />;
};
